import React from "react";
import WorkProcess from "../components/WorkProcess";
import { Clock } from "react-flaticons";
import BannerWithoutImage from "../components/BannerWithoutImage";

function About() {
  return (
    <>
      <BannerWithoutImage title="About" />
      <section className="container mx-auto" id="about-welcome">
        <div className="grid grid-rows-1 md:grid-cols-2 grid-cols-1 gap-16">
          <div className="image hidden md:block">
            <img
              src="/assets/images/about.webp"
              className="h-full object-contain"
              alt=""
            />
          </div>
          <div className="my-16">
            <div className="rounded-md bg-[#00c18a1a] py-0.5 px-2.5 border border-transparent text-sm text-[#00C18A] transition-all shadow-sm w-fit">
              Our Services
            </div>
            <div className="lg:text-4xl text-2xl heading-font-family my-5 font-bold">
              Our firm operates worldwide business since 1998.
            </div>
            <p className="mb-3 lg:text-2xl text:xl text-gray-700 dark:text-gray-400">
              We specialize in providing accounting, taxation and payroll
              processing services.
            </p>
            <div className="about-paras">
              <div className="flex gap-5 items-start">
                <img src="/assets/icon/mission.svg" alt="mission-icon" />
                <div className="flex flex-col gap-4">
                  <span className="font-bold">Our mission</span>
                  <span className="text-gray-700 leading-relaxed">
                    Our mission is to provide exceptional Accounting, Taxation &
                    Payroll Processing Services to businesses and individuals,
                    helping them achieve financial accuracy, compliance, and
                    peace of mind.
                  </span>
                </div>
              </div>
              <div className="flex gap-5 items-start">
                <img src="/assets/icon/vision.svg" alt="mission-icon" />
                <div className="flex flex-col gap-4">
                  <span className="font-bold">Our vision</span>
                  <span className="text-gray-700 leading-relaxed">
                    Our vision is to be recognized as a leading provider of
                    Accounting, Taxation & Payroll Processing Services, known
                    for our unwavering commitment to excellence, integrity, and
                    client satisfaction.
                  </span>
                </div>
              </div>
              <div className="flex gap-5 items-start">
                <img src="/assets/icon/core.svg" alt="mission-icon" />
                <div className="flex flex-col gap-4">
                  <span className="font-bold">Core values</span>
                  <span className="text-gray-700 leading-relaxed">
                    We maintain the highest standards of professionalism. Our
                    team is composed of qualified professionals who possess deep
                    expertise in their respective fields.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WorkProcess />
      <section id="features" className="bg-[#242424] py-32">
        <div className="container mx-auto">
          <div className="rounded-md bg-[#00c18a1a] py-0.5 px-2.5 border border-transparent mx-auto text-sm text-[#00C18A] transition-all shadow-sm w-fit">
            Features
          </div>
          <div className="lg:text-4xl text-2xl heading-font-family my-5 font-bold text-white text-center mx-auto w-1/2">
            Here are some features why you should choose us
          </div>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-8 mt-16">
            <div className="flex">
              <div className="flex flex-col gap-4 justify-center text-gray-400 bg-[#1A1A1A] p-8 md:w-1/2 w-full">
                <span className="text-white text-xl font-bold heading-font-family">
                  Cost efficiency
                </span>
                <span>
                  When it comes to cost efficiency in Accounting, Taxation &
                  Payroll Processing Services, here are some key points to
                  consider
                </span>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-2">
                    <img src="/assets/icon/check.svg" alt="" />
                    <span>Elimination of penalties</span>
                  </div>
                  <div className="flex gap-2">
                    <img src="/assets/icon/check.svg" alt="" />
                    <span>Technology efficiency</span>
                  </div>
                  <div className="flex gap-2">
                    <img src="/assets/icon/check.svg" alt="" />
                    <span>Access to expertise</span>
                  </div>
                  <div className="flex gap-2">
                    <img src="/assets/icon/check.svg" alt="" />
                    <span>Focus on core business</span>
                  </div>
                  <div className="flex gap-2">
                    <img src="/assets/icon/check.svg" alt="" />
                    <span>Risk management</span>
                  </div>
                </div>
              </div>
              <div className="w-1/2 hidden md:block" style={{height: '380px'}}>
                <img src="/assets/images/choose-us.webp" className="h-full" alt="" />
              </div>
            </div>
            <div className="flex flex-col h-full justify-between gap-8">
              <div className="flex bg-[#1A1A1A] p-6 gap-6">
                <div><Clock color="#00C18A" size={32}/></div>
                <div className="flex flex-col text-gray-400 gap-4">
                  <span className="text-white text-xl font-bold heading-font-family">
                    Time efficiency
                  </span>
                  <span>
                    Accounting, Taxation & Payroll Processing Service providers
                    have specialized knowledge and experience in their
                    respective fields. Their expertise allows them to
                    efficiently handle accounting tasks.
                  </span>
                </div>
              </div>
              <div className="flex bg-[#1A1A1A] p-6 gap-6">
                <div><Clock color="#00C18A" size={32}/></div>
                <div className="flex flex-col text-gray-400 gap-4">
                  <span className="text-white text-xl font-bold heading-font-family">
                    Time efficiency
                  </span>
                  <span>
                    Accounting, Taxation & Payroll Processing Service providers
                    have specialized knowledge and experience in their
                    respective fields. Their expertise allows them to
                    efficiently handle accounting tasks.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
