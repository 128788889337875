import React from "react";
import WorkProcess from "../components/WorkProcess";
import Services from "../components/Services";
import Banner from "../components/Banner";

function Home() {
  return (
    <>
      <Banner />
      <WorkProcess />
      <Services />
    </>
  );
}

export default Home;
