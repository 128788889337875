import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
function Footer() {
  return (
    <>
      <footer
        className="mx-auto pt-32 w-full max-w-container px-4 sm:px-6 lg:px-8 bg-[#242424]"
        aria-labelledby="footer-heading"
      >
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="mb-16">
          <div className="mt-10 grid lg:grid-cols-5 gap-x-8 gap-y-10 grid-cols-3">
            <div className="lg:col-start-1 lg:col-end-3 col-span-full">
              <div className="single-footer-widget text-gray-100 w-2/3 mx-auto">
                <div className="widget-logo logo">
                  <div
                    className="logo"
                    style={{ height: "80px" }}
                  >
                    <img
                      src="assets/images/almiswan-logo.svg"
                      alt="Almiswan"
                      className="h-full"
                    />
                  </div>
                </div>
                <h4 className="heading-font-family text-2xl my-4">
                  Subscribe for the latest updates!
                </h4>

                <form
                  className="mailchimp newsletter-form"
                  method="post"
                  noValidate={true}
                >
                  <div className="flex border-b border-[#00C18A] py-4 flex-wrap">
                    <input
                      type="email"
                      className="bg-transparent flex-1 border-0"
                      placeholder="Enter your email address"
                      name="EMAIL"
                      required=""
                    />

                    <button type="submit" className="flex-none">
                      <div className="flex items-center gap-2 text-[#00C18A]">
                        <FontAwesomeIcon
                          icon={faPaperPlane}
                          className="text-sm"
                        />
                        <span>Subscribe</span>
                      </div>
                    </button>
                  </div>
                  <div className="form-check mt-4">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                      required=""
                    />
                    <label className="form-check-label" htmlFor="exampleCheck1">
                      I agree to the
                      <a href="https://themes.envytheme.com/aegis/privacy-policy/">
                        Privacy Policy.
                      </a>
                    </label>
                  </div>

                  <div
                    className="mchimp-errmessage alert alert-danger"
                    style={{ display: "none" }}
                  ></div>
                  <div
                    className="mchimp-sucmessage alert alert-primary"
                    style={{ display: "none" }}
                  ></div>
                </form>
              </div>
            </div>
            <div>
              <h3 className="text-sm/6 font-semibold text-gray-100">
                Application UI
              </h3>
              <ul className="mt-4 space-y-4">
                <li className="group">
                  <a
                    className="text-sm/6 text-gray-400 hover:text-gray-100 [&amp;>span]:group-last:inline-block [&amp;>span]:group-last:transition [&amp;>span]:group-last:hover:translate-x-0.5"
                    href="/components/application-ui/lists/tables"
                  >
                    Tables
                  </a>
                </li>
                <li className="group">
                  <a
                    className="text-sm/6 text-gray-400 hover:text-gray-100 [&amp;>span]:group-last:inline-block [&amp;>span]:group-last:transition [&amp;>span]:group-last:hover:translate-x-0.5"
                    href="/components/application-ui/lists/feeds"
                  >
                    Feeds
                  </a>
                </li>
                <li className="group">
                  <a
                    className="text-sm/6 text-gray-400 hover:text-gray-100 [&amp;>span]:group-last:inline-block [&amp;>span]:group-last:transition [&amp;>span]:group-last:hover:translate-x-0.5"
                    href="/components/application-ui/forms/form-layouts"
                  >
                    Form Layouts
                  </a>
                </li>
                <li className="group">
                  <a
                    className="text-sm/6 text-gray-400 hover:text-gray-100 [&amp;>span]:group-last:inline-block [&amp;>span]:group-last:transition [&amp;>span]:group-last:hover:translate-x-0.5"
                    href="/components/application-ui/forms/select-menus"
                  >
                    Select Menus
                  </a>
                </li>
                <li className="group">
                  <a
                    className="text-sm/6 text-gray-400 hover:text-gray-100 [&amp;>span]:group-last:inline-block [&amp;>span]:group-last:transition [&amp;>span]:group-last:hover:translate-x-0.5"
                    href="/components/application-ui/forms/radio-groups"
                  >
                    Radio Groups
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-sm/6 font-semibold text-gray-100">
                Ecommerce
              </h3>
              <ul className="mt-4 space-y-4">
                <li className="group">
                  <a
                    className="text-sm/6 text-gray-400 hover:text-gray-100 [&amp;>span]:group-last:inline-block [&amp;>span]:group-last:transition [&amp;>span]:group-last:hover:translate-x-0.5"
                    href="/components/ecommerce/components/product-overviews"
                  >
                    Product Overviews
                  </a>
                </li>
                <li className="group">
                  <a
                    className="text-sm/6 text-gray-400 hover:text-gray-100 [&amp;>span]:group-last:inline-block [&amp;>span]:group-last:transition [&amp;>span]:group-last:hover:translate-x-0.5"
                    href="/components/ecommerce/components/product-lists"
                  >
                    Product Lists
                  </a>
                </li>
                <li className="group">
                  <a
                    className="text-sm/6 text-gray-400 hover:text-gray-100 [&amp;>span]:group-last:inline-block [&amp;>span]:group-last:transition [&amp;>span]:group-last:hover:translate-x-0.5"
                    href="/components/ecommerce/components/category-previews"
                  >
                    Category Previews
                  </a>
                </li>
                <li className="group">
                  <a
                    className="text-sm/6 text-gray-400 hover:text-gray-100 [&amp;>span]:group-last:inline-block [&amp;>span]:group-last:transition [&amp;>span]:group-last:hover:translate-x-0.5"
                    href="/components/ecommerce/components/shopping-carts"
                  >
                    Shopping Carts
                  </a>
                </li>
                <li className="group">
                  <a
                    className="text-sm/6 text-gray-400 hover:text-gray-100 [&amp;>span]:group-last:inline-block [&amp;>span]:group-last:transition [&amp;>span]:group-last:hover:translate-x-0.5"
                    href="/components/ecommerce/components/category-filters"
                  >
                    Category Filters
                  </a>
                </li>
                <li className="group">
                  <a
                    className="text-sm/6 text-gray-400 hover:text-gray-100 [&amp;>span]:group-last:inline-block [&amp;>span]:group-last:transition [&amp;>span]:group-last:hover:translate-x-0.5"
                    href="/components/ecommerce/components/product-quickviews"
                  >
                    Product Quickviews
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-sm/6 font-semibold text-gray-100">
                Templates &amp; UI Kits
              </h3>
              <ul className="mt-4 space-y-4">
                <li className="group">
                  <a
                    className="text-sm/6 text-gray-400 hover:text-gray-100 [&amp;>span]:group-last:inline-block [&amp;>span]:group-last:transition [&amp;>span]:group-last:hover:translate-x-0.5"
                    href="/templates/catalyst"
                  >
                    Catalyst UI Kit
                  </a>
                </li>
                <li className="group">
                  <a
                    className="text-sm/6 text-gray-400 hover:text-gray-100 [&amp;>span]:group-last:inline-block [&amp;>span]:group-last:transition [&amp;>span]:group-last:hover:translate-x-0.5"
                    href="/templates/spotlight"
                  >
                    Personal Website Template
                  </a>
                </li>
                <li className="group">
                  <a
                    className="text-sm/6 text-gray-400 hover:text-gray-100 [&amp;>span]:group-last:inline-block [&amp;>span]:group-last:transition [&amp;>span]:group-last:hover:translate-x-0.5"
                    href="/templates/salient"
                  >
                    Landing Page Template
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="items-centers grid grid-cols-1 justify-between gap-4 border-t border-gray-100 py-6 md:grid-cols-2 text-gray-100">
          <p className="text-sm/6 max-md:text-center">Almiswan@2024</p>
          <div className="flex items-center justify-center space-x-4 text-sm/6 font-semibold md:justify-end">
            <a href="/privacy-policy">Privacy policy</a>
            <div className="h-4 w-px bg-slate-200"></div>
            <a href="/changelog">Changelog</a>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
