import "./scss/styles.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Layout from "./components/Layout";
import About from "./pages/About";
import Blog from "./pages/Blog";

function App() {
  return (
    <>
    {/* <div style={{width: '200px', height: '200px', objectFit: 'contain'}}>
    <img src="/assets/images/almiswan-logo.svg" alt="" />
    </div> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="blog" element={<Blog />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
