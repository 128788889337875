import React from "react";
import BannerWithoutImage from "../components/BannerWithoutImage";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowRight, faSearch } from "@fortawesome/free-solid-svg-icons";

function Blog() {
  const blogs = [
    {
      title: "Boost your conversion rate",
      date: "Mar 16, 2024",
      tag: "Marketing",
      description:
        "Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde.Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.",
    },
    {
      title: "How to use search engine optimization to drive sales",
      date: "Mar 16, 2024",
      tag: "Sales",
      description:
        "Optio cum necessitatibus dolor voluptatum provident commodi et. Qui aperiam fugiat nemo cumque.",
    },
    {
      title: "Improve your customer experience",
      date: "Mar 16, 2024",
      tag: "Business",
      description:
        "Cupiditate maiores ullam eveniet adipisci in doloribus nulla minus. Voluptas iusto libero adipisci rem et corporis. Nostrud sint anim sunt aliqua. Nulla eu labore irure incididunt velit cillum quis magna dolore.",
    },
    {
      title: "Boost your conversion rate",
      date: "Mar 16, 2024",
      tag: "Marketing",
      description:
        "Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde.Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.",
    },
    {
      title: "How to use search engine optimization to drive sales",
      date: "Mar 16, 2024",
      tag: "Sales",
      description:
        "Optio cum necessitatibus dolor voluptatum provident commodi et. Qui aperiam fugiat nemo cumque.",
    },
    {
      title: "Improve your customer experience",
      date: "Mar 16, 2024",
      tag: "Business",
      description:
        "Cupiditate maiores ullam eveniet adipisci in doloribus nulla minus. Voluptas iusto libero adipisci rem et corporis. Nostrud sint anim sunt aliqua. Nulla eu labore irure incididunt velit cillum quis magna dolore.",
    },
    {
      title: "Boost your conversion rate",
      date: "Mar 16, 2024",
      tag: "Marketing",
      description:
        "Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde.Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.",
    },
    {
      title: "How to use search engine optimization to drive sales",
      date: "Mar 16, 2024",
      tag: "Sales",
      description:
        "Optio cum necessitatibus dolor voluptatum provident commodi et. Qui aperiam fugiat nemo cumque.",
    },
    {
      title: "Improve your customer experience",
      date: "Mar 16, 2024",
      tag: "Business",
      description:
        "Cupiditate maiores ullam eveniet adipisci in doloribus nulla minus. Voluptas iusto libero adipisci rem et corporis. Nostrud sint anim sunt aliqua. Nulla eu labore irure incididunt velit cillum quis magna dolore.",
    },
  ];
  return (
    <>
      <BannerWithoutImage title={"Our Blog"} />
      {/* <section id="blog-items" className="my-32">
        <div className="container mx-auto grid grid-cols-5 gap-16">
          <div className="col-span-3">
            <div className="blog-item">
              <div>
                <img src="/assets/images/blog7.webp" alt="" />
              </div>
              <div className="text shadow-md flex flex-col gap-3 items-start p-8">
                <div className="flex gap-4">
                  <span className="text-[#00c583]">Budget Properly</span>
                  <span>||</span>
                  <span>January 22, 2024</span>
                </div>
                <div className="text-lg font-bold heading-font-family">
                  Business Guide For Young Professionals
                </div>
                <div className="text-gray-500">
                  The service provider initiates the procin initial consultation
                  with magna.
                </div>
                <button>
                  Learn More <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            </div>
          </div>
          <div className="col-span-2 bg-[#F1F4FB]">
            <div className="text-lg font-bold heading-font-family">Search</div>
            <div className="flex items-center bg-[#fff]">
              <input
                type="text"
                placeholder="Search Here"
                className="border-0 w-full"
              />
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <div className="text-lg font-bold heading-font-family">Categories</div>
            <ul>
              <li>Account Payroll</li>
            </ul>
          </div>
        </div>
      </section> */}
      <div class="bg-white py-24 sm:py-32">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
          <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {blogs.map((blog, index) => {
              return (
                <article class="flex max-w-xl flex-col items-start justify-between shadow-md p-2 rounded">
                  <div class="flex items-center gap-x-4 text-xs">
                    <time datetime="2020-03-16" class="text-gray-500">
                      {blog.date}
                    </time>
                    <a
                      href="#"
                      class="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                    >
                      {blog.tag}
                    </a>
                  </div>
                  <div class="group relative">
                    <h3 class="mt-3 text-lg/6 font-semibold text-gray-900 group-hover:text-gray-600">
                      <a href="#">
                        <span class="absolute inset-0"></span>
                        {blog.title}
                      </a>
                    </h3>
                    <p class="mt-5 line-clamp-3 text-sm/6 text-gray-600">
                      {blog.description}
                    </p>
                  </div>
                  <div class="relative mt-8 flex items-center gap-x-4">
                    <img
                      src="https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                      class="size-10 rounded-full bg-gray-50"
                    />
                    <div class="text-sm/6">
                      <p class="font-semibold text-gray-900">
                        <a href="#">
                          <span class="absolute inset-0"></span>
                          Michael Foster
                        </a>
                      </p>
                      <p class="text-gray-600">Co-Founder / CTO</p>
                    </div>
                  </div>
                </article>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog;
