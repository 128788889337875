import React from "react";

function Banner({ page = "home" }) {
  return (
    <>
      <section
        id="banner"
        className="py-4 bg-no-repeat bg-cover relative bg-center"
      >
        <div className="container mx-auto relative z-10 h-full flex items-center">
          <div className="lg:w-5/12 flex flex-col gap-8 lg:items-start items-center">
            <h1 className="lg:text-8xl text-6xl font-bold text-gray-100 heading-font-family lg:text-left text-center">
              {page === "about"
                ? "About US"
                : "Empowering Your Financial Future"}
            </h1>
            <p className="md:text-xl text-gray-100 lg:text-left text-center">
              Expert Guidance for Lasting Financial Success
            </p>
            <button className="bg-[#00C18A] py-4 px-8 rounded text-gray-100">
              Explore Our Services
            </button>
          </div>
        </div>
      </section>
    </>
  );
}

export default Banner;
